import {FunctionComponent} from 'react'
import {useTranslation} from 'react-i18next'


// Svg

import storage from '../../util/storage'
import Account from '../svg/Account'

interface Props {
  hasIcon: boolean
}

const UserProfile: FunctionComponent<Props> = ({hasIcon}) => {
  const { t } = useTranslation()

  const userProfile = storage.getUserInformation()
  
  return (
    userProfile ? (
      <div className='u-pxm user-profile'>
        {hasIcon && <span className='u-mxl'><Account /></span>}
        <span>{ userProfile.firstname }</span>
        <span>&nbsp;</span>
        <span>{ userProfile.lastname }</span>
        <span>&nbsp;</span>
        <span>({ userProfile.username })</span>  
      </div>
    ) : (<> </>)
  );
}

export default UserProfile
